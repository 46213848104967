import { render, staticRenderFns } from "./404.vue?vue&type=template&id=58dff818&scoped=true&"
import script from "./404.js?vue&type=script&lang=js&"
export * from "./404.js?vue&type=script&lang=js&"
import style0 from "./errors.scss?vue&type=style&index=0&id=58dff818&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58dff818",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/luizmottin/Workspace/Trackmob/trackmob-collect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58dff818')) {
      api.createRecord('58dff818', component.options)
    } else {
      api.reload('58dff818', component.options)
    }
    module.hot.accept("./404.vue?vue&type=template&id=58dff818&scoped=true&", function () {
      api.rerender('58dff818', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/errors/404.vue"
export default component.exports