var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-404" }, [
    _c("img", {
      attrs: {
        src: require("@/assets/illustration-erro.svg"),
        alt: "404 image"
      }
    }),
    _c("h1", { staticClass: "title" }, [
      _c("span", { staticClass: "error" }, [_vm._v("404")]),
      _vm._v(" " + _vm._s(_vm.$t("errors.PageNotFound")))
    ]),
    _c("p", { staticClass: "description" }, [
      _vm._v(_vm._s(_vm.$t("errors.Description")))
    ]),
    _c("div", { staticClass: "wrap-buttons" }, [
      _c("a", {
        staticClass: "tui tuim ui basic large fluid button",
        attrs: {
          href: "https://trackmob.octadesk.com/login",
          target: "_blank"
        },
        domProps: { innerHTML: _vm._s(_vm.$t("SpeakWithSupport")) }
      }),
      _c("a", {
        staticClass: "tui tuim ui primary large fluid button",
        domProps: { innerHTML: _vm._s(_vm.$t("Back")) },
        on: {
          click: function($event) {
            return _vm.$router.go(-1)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }